import "./styles.scss"
const About = () => {
    return (
        <section id="about" class="section">
            <div class="container about">
                <h2 class="py-4">About Us</h2>

                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <img class="img-fluid" src="bc1.avif" />

                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <p class="content">
                            Welcome to Momchick, a culinary haven nestled along the picturesque shores of Calicut Beach. Our restaurant stands as a beacon of flavor, offering a delightful blend of warmth and taste against the backdrop of the serene beachfront. At Momchick, we are committed to crafting an exceptional broasted chicken experience, where tradition meets innovation, and every dish is a celebration of culinary excellence.
                        </p>


                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-lg-6 col-sm-12">
                        <p class="content">
                            Immerse yourself in the inviting ambiance of our Calicut Beach location, where the rhythmic waves provide the perfect soundtrack to your dining experience. Our menu is a testament to the artistry of broasting, showcasing a symphony of flavors that range from classic crispy delights to signature creations that push the boundaries of traditional chicken offerings. Each piece of chicken is carefully marinated and expertly pressure fried to golden perfection, ensuring a harmonious balance of tenderness and crispiness. Momchick Broasted Chicken is not just a restaurant; it's a destination where families, friends, and solo diners come together to savor the magic of broasted goodness in every bite. Join us at Calicut Beach and embark on a culinary journey that transforms a simple meal into an unforgettable dining experience.
                        </p>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                    <img class="img-fluid" src="burger1.jpeg" />

                    </div>
                </div>

            </div>
        </section>
    )
}

export default About;