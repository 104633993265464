

const Home = () => {

    const OSDivStyle = {
        backgroundColor: "red",
        color: "Yellow",
        fontSize: 28
    }
    return (
        <section id="home" class="section home">
            <div class="container">
                <div class="row">
                    {/* <div class="py-5">
                        <img class="img-fluid" src="MFC2.jpeg"></img>
                        <div class="col-lg-4 col-sm-12 py-5 logo">
                            <img class="img-fluid" src="momchick-logo.png"></img>
                        </div>
                    </div> */}
                    {/* <div class="col-lg-4 col-sm-12 py-5 logo">
                        <img class="img-fluid" src="momchick-logo.png"></img>
                    </div> 
                    <div class="col-lg-8 col-sm-12 py-5 soon" >
                        <img class="img-fluid" src="mfc.jpeg"></img>
                    </div> */}
                    <div id="carouselExampleRide" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src="mfc.jpeg" class="d-block w-100" alt="..."/>
                            </div>
                            <div class="carousel-item" >
                                <img src="mfc2.jpeg" class="d-block w-100" alt="..."/>
                            </div>
                            <div class="carousel-item" >
                                <img src="mfc3.jpeg" class="d-block w-100" alt="..."/>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Home;