
import "./styles.scss"
const Header = () => {

    return (
        <header class="">
            <nav class="navbar  navbar-expand-lg navbar-dark navbar-red fixed-top" >
                <div class="container-fluid">
                    <a class="navbar-brand display-1" href="#">
                        <img src="momchick-logo.png" height="100" alt="Momchick" class="d-inline-block align-text-top" />
                        {/* Momchick */}
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarLinks" aria-controls="navbarLinks"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarLinks">
                        <ul class="navbar-nav h5">
                            <li class="nav-item">
                                <a class="nav-link" href="#home">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#about">About</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#contact">Contact </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </nav>
        </header>
    )
}

export default Header;