

const Footer = () => {
    return (
        <footer class="section footer">
            <div class="container">
                <div class="row social">
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/profile.php?id=61553572700574">
                                <i className="fa fa-facebook fa-2x"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com/momchickrestaurant?igshid=OWZmcWRjbzUxN29p">
                                <i className="fa fa-instagram fa-2x"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/Momchick141328">
                                <i className="fa fa-twitter fa-2x"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/@Momchick">
                                <i className="fa fa-youtube fa-2x"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="copyright">
                    Copyright © Momchick 2023 All Rights Reserved
                </div>

            </div>
        </footer>
    )
}

export default Footer;