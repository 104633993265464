const Contact = () => {
    return (
        <section id="contact" class="section contact">
            <div class="container ">
                <h2 class="py-5 text-center">Contact Us</h2>


                <div class="row p-5">
                    <div class="col-lg-6 col-sm-12">
                        <div class="row">
                            <div class="col-lg-2 col-sm-12 title contact-icon  p-4">
                                <i className="fa fa-phone fa-2x"></i>
                            </div>
                            <div class="col-lg-10 col-sm-12 p-4">
                                <div class="fw-bold">Phone</div>
                                <div>9496629998</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="row">
                            <div class="col-lg-2 col-sm-12 title contact-icon  p-4">
                                <i className="fa fa-map-location-dot fa-2x"></i>
                            </div>
                            <div class="col-lg-10 col-sm-12 p-4">
                                <div class="fw-bold">Location</div>
                                <div>
                                    <a class="" href="https://maps.app.goo.gl/bMptFiVAqj5MQJHu7">Go to map</a>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div class="row p-5">
                    <div class="col-lg-6 col-sm-12">
                        <div class="row">
                            <div class="col-lg-2 col-sm-12 title contact-icon  p-4">
                                <i className="fa fa-envelope fa-2x"></i>
                            </div>
                            <div class="col-lg-10 col-sm-12  p-4">
                                <div class="fw-bold">Email</div>
                                <div>themomchick@gmail.com</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="row">
                            <div class="col-lg-2 col-sm-12 title contact-icon  p-4">
                                <i className="fa fa-location-dot fa-2x"></i>
                            </div>
                            <div class="col-lg-10 col-sm-12 p-4">
                                <div class="fw-bold">Address</div>
                                <div>
                                    61/9007 B Momchick Food Products Guajarati Street Kozhikode
                                    South Circle Kozhikode Kerala - 673001
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
